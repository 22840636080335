<template>
  <div>
    <validation-observer ref="pageFields" #default="{ invalid }">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group label="Tipo *" label-for="tipo">
              <v-select v-model="inputSelected" :options="inputOptions" name="tipo"> </v-select>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Tag *" label-for="nuevoCampo">
              <b-form-input id="nuevoCampo" v-model="field" placeholder="ej: titulo - title" name="nuevoCampo" @keydown.enter.native="addField" />
            </b-form-group>
          </b-col>

          <b-col md="2" class="mt-2">
            <b-button :disabled="invalid || $apiCall.state || !field || !inputSelected" block variant="primary" type="button" @click="addField">
              +
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { BCard, BForm, BRow, BCol, BFormInput, BFormGroup, BButton } from 'bootstrap-vue'

import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'

localize('es')

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    vSelect,

    ValidationProvider,
    ValidationObserver
  },
  props: {
    hasGroup: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      inputOptions: this.hasGroup ? ['INPUT', 'EDITOR', 'GROUP'] : ['INPUT', 'EDITOR', 'IMAGES'],
      inputSelected: null,
      field: null,
      // validations
      required
    }
  },
  methods: {
    addField() {
      this.$emit('addField', { inputSelected: this.inputSelected, field: this.field })
    },
    reset() {
      // limpia los campos
      this.field = null
      this.inputSelected = null
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
